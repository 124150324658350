/* teal: #53f6c7 */
* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100%;
}

html,
html a {
  -webkit-font-smoothing: antialiased !important;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

body {
  padding-top: 10px;
  background: -webkit-linear-gradient(left, #1d1b24 0%,#1d1b24 82%,#211f28 82%,#211f28 100%);
  background: linear-gradient(to right, #1d1b24 0%,#1d1b24 82%,#211f28 82%,#211f28 100%);
  border-left: 10px solid #53f6c7;
  font-family: 'Roboto', sans-serif;
  color: #fff;
}

body:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #53f6c7;
  z-index: 9999;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Space Mono', monospace;
  font-weight: normal;
}

p {
  line-height: 1.3;
}

img {
  max-width: 100%;
  height: auto;
}

.site-container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding-left: 70px;
}

nav ul {
  display: flex;
  position: relative;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

nav ul li {
  margin: 0;
  padding: 0;
}

nav ul li a {
  display: block;
  padding: 10px;
  text-decoration: none;
  text-transform: uppercase;
  color: white;
  background-color: transparent;
  -webkit-transition: all 250ms ease-in;
  transition: all 250ms ease-in;
}

nav ul li a:hover {
  padding: 18px 10px;
  background-color: rgb(223, 81, 121);
  color: white;
}

nav ul li a.active {
  padding: 18px 10px;
  background-color: rgb(223, 81, 121);
  color: white;
}

a {
  color: rgb(223, 81, 121);
  text-decoration: none;
  -webkit-transition: all 250ms ease-in;
  transition: all 250ms ease-in;
}

hr {
  margin: 10px 0;
  border: none;
  width: 100px;
  height: 10px;
  background-color: rgb(223, 81, 121);
}

a:hover {
  color: #53f6c7;
}

.image-accent::before {
  content: '';
  display: block;
  position: absolute;
  top: -10px;
  left: 3px;
  height: 100px;
  width: 60px;
  z-index: -1;
  background-color: rgb(223, 81, 121);
}

.button {
  display: inline-block;
  position: relative;
  padding: 15px 20px;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 1;
  color: #53f6c7;
  border-left: 5px solid #53f6c7;
  border-bottom: 5px solid #53f6c7;
  -webkit-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}
.button:before {
  content: '';
  display: block;
  position: absolute;
  top: -5px;
  left: -5px;
  width: calc(100% + 5px);
  height: calc(100% + 6px);
  background-color: #53f6c7;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: left;
          transform-origin: left;
  -webkit-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  z-index: -1;
}
.button:hover {
  color: white;
  border-left: 5px solid rgb(223, 81, 121);
  border-bottom: 5px solid rgb(223, 81, 121);
}
.button:hover:before {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  background-color: rgb(223, 81, 121);
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-exit {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  -webkit-transition: opacity 300ms ease-in;
  transition: opacity 300ms ease-in;
}

